<template>
  <div class="flex items-center w-full">
    <UiConfirm
      :action="() => {
        $router.go(-1)
      }"
      title="Tem certeza que deseja sair do bloco?"
      description=""
      btnColor="dangerUp"
      btnConfirm="Confirmar"
      btnCancel="Cancelar"
    >
      <CloseButton />
    </UiConfirm>
    <div class="w-full ml-2 md:ml-4">
      <QuestionSlider :elapsed-time="!isLoading ? elapsedTime : ''" :step="!isLoading ? stepNumber : 0" />
    </div>

  </div>
</template>

<script lang="ts" setup>
import CloseButton from '../close-button.vue'
import { QuestionSlider } from '~/components/question'
import { UiConfirm } from '~/components/ui'

const route = useRoute()

const {
  isLoading,
  elapsedTime,
  stepNumber,
} = useBlockHandler(
  route.params.subject as string,
  route.query.competenceId as string | undefined
);
</script>
