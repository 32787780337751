<template>
  <UiModal :no-rounding="isMobile" :full="isMobile" :show-close-button="false" non-closable no-padding no-overflow
    v-model="modalOpened">
    <div class="relative overflow-auto max-h-[100dvh] md:max-h-[87vh] bg-[#E8FAFE] md:px-4">

      <div class="container relative z-40">
        <!-- Premium title -->
        <div class="flex w-full justify-between items-center mt-4">
          <Motion as="button" :press="{ scale: 0.96 }" @click="modalOpened = false"
            class="bg-white flex items-center justify-center p-2 rounded-full border border-accent shadow">
            <X class="size-6" />
          </Motion>

          <h3 class="md:text-4xl text-3xl font-bold font-serif">
            premium
          </h3>
        </div>
      </div>

      <!-- Decorator element -->

      <!-- Plans comparasion -->
      <div class="container pt-8">
        <div class="">
          <h1 class="text-xl md:text-2xl font-bold text-[#252120] text-[28px] text-center mb-4 md:mb-8">
            Tenha a experiência completa
          </h1>

          <!-- Headers -->
          <div class="flex justify-end items-center gap-4">
            <div class="font-medium">
              Grátis
            </div>
            <div class="font-serif text-[18px]">
              Premium
            </div>
          </div>

          <!-- Items -->
          <div class="relative flex flex-col mt-2">
            <div v-for="(item, index) in benefitsComparison" :key="item.title"
              class="relative z-10 py-3 flex justify-between items-center" :class="{
                'border-b border-[#B5B5B5]': index !== benefitsComparison.length - 1
              }">
              <div class="text-base text-[#252120]">
                {{ item.title }}
              </div>
              <div class="ml-auto min-w-[60px] flex justify-center">
                <Check stroke-width="3" v-if="item.free" class="size-6 text-green-500" />
                <X stroke-width="3" v-else class="size-6 text-red-500" />
              </div>
              <div class="ml-4 min-w-[60px] flex justify-center">
                <Check stroke-width="3" v-if="item.premium" class="size-6 text-green-500" />
                <X stroke-width="3" v-else class="size-6 text-red-500" />
              </div>
            </div>

            <!-- Decorator -->
            <div class=" absolute top-0 right-0 w-[60px] h-full bg-white rounded-2xl">

            </div>
          </div>
        </div>
      </div>

      <div class="bg-[#E8FAFE] pt-10 pb-[270px]" />

      <!-- Start trial box -->
      <div
        class="fixed border-t-2 border-accent left-0 bottom-0 min-h-[220px] z-50 bg-white w-full rounded-3xl  p-4 px-6 shadow-2xl text-center transition-all duration-[0.4s]">
        <div>
          <!-- List plans -->
          <div class="grid gap-3">
            <h5 class="text-gray-600">
              Escolha seu plano
            </h5>
            <div v-for="(p, i) in plans"
              class="relative border-2 cursor-pointer rounded-[14px] p-2 px-4 flex items-center transition-all"
              @click="plan = p.id" :class="{
                'border-primary text-primary bg-primary/5': plan === p.id,
                'border-accent/60': plan !== p.id,
              }">
              <div class="inline-flex flex-col items-start font-serif text-[20px]">
                {{ p.name }}
              </div>
              <div class="ml-auto text-right text-black">
                <div class="font-bold tracking-wide text-lg">
                  {{ p.byMonthFormated }} / mês
                </div>
                <div class="text-sm text-accent whitespace-nowrap">
                  Total de {{ p.totalFormated }}
                </div>
              </div>
            </div>

            <UiButton @click="goToPayment" class="mt-2">
              <div class="flex flex-col">
                <span>Continuar</span>
              </div>
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import { UiModal, UiButton } from '~/components/ui';
import { usePlans } from '~/domains/payment/composables/usePlans';
import { ChevronLeft, Check, X } from 'lucide-vue-next';

const { plans, plan, modalOpened, benefitsComparison, goToPayment } = usePlans();

const isMobile = computed(() => {
  return typeof window !== 'undefined' && window.innerWidth < 768;
})
</script>