import NavDefault from './default.vue'
import NavBlock from './block.vue'
import NavTest from './test.vue'
export const NavbarVariants = [
  {
    name: 'test-result',
    component: NavDefault,
    routes: [/^\/materia\/[^/]+\/simulado\/[^/]+\/relatorio$/, /^\/materia\/[^/]+\/simulado\/[^/]+\/revisao$/],
  },
  {
    name: 'block',
    component: NavBlock,
    routes: [/^\/materia\/[^/]+\/bloco$/]
  },
  {
    name: 'test',
    component: NavTest,
    routes: [/^\/materia\/[^/]+\/simulado\/[^/]+$/]
  },
  {
    name: 'default',
    component: NavDefault,
    routes: [/.*/]
  }
]