<template>
  <div class="gap-4 inline-flex ml-4 md:ml-auto w-full md:w-[300px]">
    <div v-if="!isInReviewMode"
      class="w-full h-8 border-2 border-accent rounded-md  px-4 flex items-center font-serif text-base  justify-center">
      <div v-if="finishTimeCountdown.totalTimeLeftInSeconds > 0" class="font-serif inline-flex items-center gap-2"
        :class="{
          'text-red-500': finishTimeCountdown.totalTimeLeftInSeconds <= 10 * 60
        }">
        <img src="/images/icons/hourglass.svg" class="size-4" />
        <span v-if="finishTimeCountdown.hours > 0">{{ finishTimeCountdown.hours }}h</span>
        <span v-if="finishTimeCountdown.hours > 0 && finishTimeCountdown.minutes > 0">:</span>
        <span v-if="finishTimeCountdown.minutes > 0">{{ finishTimeCountdown.minutes }}m</span>
      </div>
      <div v-else class="font-sans font-bold text-sm whitespace-nowrap text-red-500 inline-flex items-center gap-2">
        ⚠️ Tempo terminado
      </div>
    </div>

    <div v-if="!isInReviewMode"
      class="w-full h-8 border-2 border-accent rounded-md  px-4 flex items-center justify-center font-serif text-base">

      <div class="font-serif text-base inline-flex items-center gap-2">
        <img src="/images/icons/questions.svg" class="size-4" />
        {{ answeredQuestions }} / {{ totalQuestions }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentTest } from '~/domains/test/composables/useCurrentTest';

const { answeredQuestions, totalQuestions, finishTimeCountdown, isInReviewMode } = useCurrentTest();
</script>