<template>
  <div
    class="sticky top-[80px] border-2 border-accent bg-white w-full h-[30px] rounded-[5px] z-30"
  >
    <div
      class="h-full bg-[#D7FFCB] border-accent rounded-[5px] transition-all"
      v-show="sliderPercentage > 0"
      :class="{
        'border-r-2': step < maxSteps,
      }"
      :style="{
        width: `${sliderPercentage}%`,
      }"
    />
    <div
      class="absolute top-1/2 -translate-y-1/2 leading-none right-2 font-serif"
    >
      {{ step }} / {{ maxSteps }}
    </div>
    <div
      v-if="elapsedTime"
      class="absolute top-1/2 -translate-y-1/2 leading-none left-2 font-serif"
    >
      {{ elapsedTime }}
    </div>
  </div>
</template>

<script setup lang="ts">
interface QuestionSliderProps {
  elapsedTime?: string;
  step: number;
  maxSteps?: number;
}

const props: QuestionSliderProps = defineProps<QuestionSliderProps>();

const maxSteps = props.maxSteps ?? 5;

const sliderPercentage = computed(() => {
  return (props.step / maxSteps) * 100;
});
</script>
