<template>
  <div class="w-full sm:w-[380px]">
    <div class="bg-white z-10 whitespace-nowrap flex flex-col overflow-hidden">
      <div class="flex flex-col px-6 py-4">
        <h4 class="text-xl font-semibold">Olá, {{ firstName }}</h4>
        <div class="flex gap-4 items-center mt-1">
          <div class="inline-flex items-center">
            <img src="/images/icons/heart.png" class="w-5 h-5 mr-1" />
            <span v-if="!authStore.isPremium" class="text-sm text-gray-700 font-semibold">{{ numberOfHearts }}</span>
            <span v-else class="text-sm text-gray-700 font-semibold">Ilimitado</span>
          </div>
          <div class="inline-flex items-center">
            <img src="/images/icons/star.png" class="size-[18px] mr-1" />
            <span v-if="!authStore.isPremium" class="text-sm text-gray-500">Usuário gratuito</span>
            <span v-else class="text-sm text-gray-700 font-semibold">Usuário premium</span>
          </div>
        </div>
        <UiButton v-if="!authStore.isPremium" size="sm" @click="() => openPlansModal()" bg-color="secondaryLight"
          class="mt-4">
          <div class="flex items-center">
            <img src="/images/icons/heart.png" class="w-5 h-5 mr-1" />
            <b class=" whitespace-nowrap">Liberar resoluções ilimitadas</b>
          </div>
        </UiButton>
        <span v-if="!authStore.isPremium && numberOfHearts === 0"
          class="text-center text-sm text-gray-500 mt-2 inline-flex items-center justify-center">
          Novos <img src="/images/icons/heart.png" class="w-5 h-5 mx-1" /> em {{ finishTimeCountdown.hours }}h {{
            finishTimeCountdown.minutes }}m {{ finishTimeCountdown.seconds }}s
        </span>
      </div>

      <button
        v-if="selectedItems.length > 0"
        class="relative px-6 py-2 font-medium md:hover:text-primary text-left border-t-2 border-accent hover:bg-gray-300/10"
        @click="selectedItems = []">
        <span class="mr-2">⬅️</span>
        Voltar
      </button>

      <component :is="item.href ? 'a' : 'button'"
        class="relative px-6 py-2 font-medium md:hover:text-primary text-left border-t-2 border-accent hover:bg-gray-300/10"
        :to="item.to" :href="item.href" target="_blank" v-for="(item, i) in currentItems" :key="item.title"
        @click="executeAction(item)">
        <span class="mr-2">{{ item.emoji }}</span>
        {{ item.title }}

        <v-icon v-if="item.subItems" :scale="1.4" name="bi-arrow-right-short"
          class="absolute right-4 top-1/2 -translate-y-1/2" />
      </component>

      <div v-if="isApp && selectedItems.length === 0" class="flex flex-col">
        <a :href="termsLink" target="_blank"
          class="px-6 py-2 hover:text-primary text-left border-t-2 border-accent hover:bg-gray-300/10 inline-block">
          Termos de uso
        </a>
        <a href="https://respostacerta.com/privacidade" target="_blank"
          class="px-6 py-2 hover:text-primary text-left border-t-2 border-accent hover:bg-gray-300/10 inline-block">
          Política de privacidade
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePlans } from '~/domains/payment/composables/usePlans';
import { capitalize } from '~/libs/utils';
import { UiButton } from '../ui';
import { dayjs } from '~/libs/dayjs';
import { useNavbar } from '~/domains/navbar/composables/useNavbar';

const authStore = useAuthStore();
const isApp = useIsApp()

const { menuItems, openedMenu } = useNavbar()

const selectedItems = ref<any[]>([])

const { termsLink } = useDevice()

const currentItems = computed(() => {
  const items = selectedItems.value.length > 0 ? selectedItems.value : menuItems.value
  return items
})

const { openPlansModal } = usePlans()

const firstName = computed(() => {
  const name = authStore.user?.fullname?.split(' ')[0]?.trim()
  return !!name ? capitalize(name) : 'Usuário'
})

const executeAction = (item: any) => {
  if (item.subItems) {
    selectedItems.value = item.subItems
    return
  }

  if (item.action) {
    item.action()
  } else if (item.to) {
    navigateTo(item.to)
  }
  openedMenu.value = null
}

const numberOfHearts = computed(() => {
  const todayRemainingToday = Math.max(3 - authStore.totalQuestionsToday, 0)
  const remainingOverall = Math.max(7 - authStore.totalQuestions, 0)

  return Math.max(todayRemainingToday + remainingOverall, 0)
});

const today = ref(dayjs())
const shouldRun = ref(true)


const todayDayOfWeek = computed(() => {
  return dayjs().day();
});

const endOfDay = computed(() => {
  return dayjs().endOf('day');
})

const { finishTimeCountdown } = useCountdown(endOfDay.value.toDate())

const run = () => {
  if (!shouldRun.value) return;

  today.value = dayjs();
  setTimeout(run, 5000);
}

onMounted(() => {
  run();
})

onUnmounted(() => {
  shouldRun.value = false
})
</script>
