import { usePlans } from "~/domains/payment/composables/usePlans"
import { NavbarVariants } from "../components/variants"

const menuOpen = ref(false)
const openedMenu = ref<string | null>(null)

export const useNavbar = () => {
  const router = useRouter()
  const route = useRoute()
  const { openPlansModal } = usePlans();
  const { isIosOnWeb, isAndroidOnWeb } = useDevice()

  const authStore = useAuthStore()

  const isInHome = computed(() => route.fullPath.includes('/inicio'))

  const currentNav = computed(() => {
    return NavbarVariants.find(nav => nav.routes.some(r => r.test(route.path)))?.component ?? null
  })

  const goBack = () => {
    router.go(-1)
  }

  const menuItems = computed(() => {
    const items: NavBarItem[] = [
      {
        title: 'Minha conta',
        emoji: '👤',
        to: '/minha-conta',
        subItems: [
          {
            title: "Meus dados",
            emoji: "👤",
            to: "/minha-conta"
          },
          {
            title: "Língua estrangeira",
            emoji: "🌍",
            to: "/minha-conta"
          },
          {
            title: "Assinatura",
            emoji: "⭐",
            to: "/minha-conta/assinatura"
          },
          {
            title: 'Senha',
            emoji: '🔐',
            to: '/minha-conta/senha'
          },
          {
            title: 'Excluir conta',
            emoji: '🚨',
            to: '/minha-conta/deletar'
          },
        ]
      }
    ]

    // Notifications
    items.push({
      title: 'Notificações',
      emoji: '🔔',
      to: '/preferencias/notificacoes',
    })

    if (!authStore.isPremium) {
      items.push({
        title: 'Seja Premium',
        emoji: '⭐',
        action: () => {
          openPlansModal();
          console.log('openPlansModal')
        },
      })
    }

    if (isIosOnWeb.value) {
      items.push({
        title: 'Baixar na App Store',
        emoji: '',
        href: 'https://apps.apple.com/us/app/respostacerta-quest%C3%B5es-enem/id6740682044',
      })
    }

    if (isAndroidOnWeb.value) {
      items.push({
        title: 'Baixar no Google Play',
        emoji: '📱',
        href: 'https://play.google.com/store/apps/details?id=com.grqtech.respostacerta',
      })
    }


    items.push({
      title: 'Recarregar',
      emoji: '🔄',
      action: () => {
        window.location.reload()
      },
    })

    return [
      ...items,
      {
        title: 'Ajuda',
        emoji: '❓',
        to: '/ajuda',
      },
      {
        title: 'Sair',
        emoji: '👋',
        to: '/sair',
      },
    ]
  })


  return {
    goBack,
    currentNav,
    menuOpen,
    menuItems,
    isInHome,
    openedMenu
  }
}

export type NavBarItem = {
  title: string
  emoji: string
  to?: string
  href?: string
  action?: () => void,
  subItems?: {
    title: string
    emoji: string
    to?: string
    href?: string
    action?: () => void
  }[]
}