<template>
  <div class="flex ml-auto">
    <UiNavitem id="streak" icon="/images/icons/fire.png" :value="authStore.studentStreak.streak">
      <StreakPanel />
    </UiNavitem>
    <UiNavitem id="stars" icon="/images/icons/star.png" :value="authStore.totalStars">
      <StarsPanel />
    </UiNavitem>
    <UiNavitem id="user" icon="/images/icons/user.svg" user>
      <UserPanel />
    </UiNavitem>
  </div>
</template>

<script type="ts" setup>
import { UiNavitem } from "~/components/ui";
import StreakPanel from '~/components/home/streak-panel.vue'
import StarsPanel from '~/components/home/stars-panel.vue'
import UserPanel from '~/components/home/user-panel.vue'

const authStore = useAuthStore()
</script>