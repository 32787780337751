<template>
  <div class="flex items-center w-full">
    <UiConfirm :action="() => {
      $router.go(-1)
    }" title="Tem certeza que deseja sair do simulado?" description="" btnColor="dangerUp" btnConfirm="Confirmar"
      btnCancel="Cancelar">
      <CloseButton />
    </UiConfirm>
    <div class="flex justify-end w-full">
      <TestMapTimer v-if="!isLoading" class="" />
    </div>

  </div>
</template>

<script lang="ts" setup>
import CloseButton from '../close-button.vue'
import { UiConfirm } from '~/components/ui'
import TestMapTimer from '~/components/test/test-map-timer.vue'
import { useCurrentTest } from '~/domains/test/composables/useCurrentTest'

const route = useRoute()

const { isLoading } = useCurrentTest()
</script>
