<template>
  <Navigators />
  <Buttons v-if="!hideButtons" />
</template>

<script type="ts" setup>
import Navigators from '../navigators.vue'
import Buttons from '../buttons.vue'
import { useNavbar } from '../../composables/useNavbar'

const { hideButtons } = useNavbar()
</script>