<template>
  <div class="pb-[50px] bg-white" :class="{ '!pb-[100px]': shouldHaveExtraPadding }">
    <nav class="h-[62px] shadow-lg fixed top-0 w-screen z-50 bg-white"
      :class="{ '!h-[110px] pt-[48px]': shouldHaveExtraPadding }">
      <div class="container flex items-center h-full">
        <component :is="currentNav" />
      </div>
    </nav>
  </div>
</template>

<script type="ts" setup>
import { useNavbar } from '../composables/useNavbar'

const { currentNav } = useNavbar()
const { info } = useDevice()

const shouldHaveExtraPadding = computed(() => {
  return info.value.isApp && info.value.isIphone && info.value.isPortrait
})
</script>