<template>
  <UiModal :full="true" :show-close-button="false" v-model="modalOpened">
    <div ref="paywallContainer" class="h-screen w-screen relative overflow-auto"
      :class="{ 'pt-14': shouldHaveExtraPadding }">

      <div class="container relative z-40">
        <!-- Premium title -->
        <div class="flex w-full justify-between items-center">
          <Motion as="button" :press="{ scale: 0.96 }" @click="modalOpened = false"
            class="bg-white flex items-center justify-center p-2 rounded-full border border-accent shadow">
            <X class="size-6" />
          </Motion>

          <h3 class="text-4xl font-bold font-serif">
            premium
          </h3>
        </div>
      </div>

      <!-- Trial section -->
      <div v-if="hasTrialEligibility" class="container">
        <!-- CTA -->
        <h2 class="text-2xl font-black text-[#252120] text-[28px] mt-8">
          Comece seu teste grátis
        </h2>

        <!-- Plan progress -->
        <div class="flex mt-6 items-center gap-4">
          <!-- Side -->
          <div class="flex flex-col gap-2">
            <div class="bg-primary p-3 rounded-full">
              <Lock class="size-5 text-white" />
            </div>

            <!-- Divider -->
            <hr class="h-12 w-1 bg-primary rounded-lg mx-auto" />

            <div class="bg-[#DAF0FF] p-3 rounded-full">
              <Bell class="size-5 text-primary" />
            </div>

            <hr class="h-12 w-1 bg-primary rounded-lg mx-auto" />

            <div class="bg-[#DAF0FF] p-3 rounded-full">
              <Star class="size-5 text-primary" />
            </div>
          </div>

          <!-- Main -->
          <div class="flex flex-col gap-8">
            <div>
              <h4 class="text-lg font-bold">
                Hoje: Ganhe acesso completo
              </h4>
              <p class="text-[#54514E]">
                Você poderá usar todas as funcionalidades sem restrições.
              </p>
            </div>

            <div>
              <h4 class="text-lg font-bold">
                Dia 2: Lembrete
              </h4>
              <p class="text-[#54514E]">
                Nós te enviaremos um lembrete quando o período de teste estiver prestes a acabar. Cancele quando quiser.
              </p>
            </div>

            <div>
              <h4 class="text-lg font-bold">
                Dia 3: Fim do teste
              </h4>
              <p class="text-[#54514E]">
                O plano pago começará em {{ trialEndsAt }}.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Decorator element -->
      <div class="relative h-[300px] w-full -mt-8 overflow-hidden" :class="{
        '!-mt-48 !h-[200px]': !hasTrialEligibility
      }">
        <img src="/images/payment/wave.svg" tabindex="-1"
          class="absolute -top-0 left-0 w-full h-full object-cover z-10" />

        <img v-if="hasTrialEligibility" class="absolute -bottom-12 right-0 z-20" src="/images/payment/girl_above.svg"
          tabindex="-1" />
        <img v-if="hasTrialEligibility" class="absolute -bottom-12 right-0" src="/images/payment/girl_below.svg"
          tabindex="-1" />

        <div class="absolute bottom-2 z-30 container">
          <AnimatePresence>

            <Motion v-if="hasTrialEligibility" as="button" :press="{ scale: 0.96 }" :initial="{
              opacity: 0
            }" :animate="{
              opacity: 1
            }" :exit="{
              opacity: 0
            }" @click="scrollToBottom"
              class="select-none px-10 h-10 w-full rounded-full bg-primary border-accent border-2 text-white font-medium mx-auto">
              Mais detalhes sobre o Premium
            </Motion>
          </AnimatePresence>
        </div>
      </div>

      <!-- Plans comparasion -->
      <div class="bg-[#DAF0FF] pt-8 pb-20">
        <div class="container">
          <h1 class="text-2xl font-bold text-[#252120] text-[28px] text-center mb-8">
            Tenha a experiência completa
          </h1>

          <!-- Headers -->
          <div class="flex justify-end items-center gap-4">
            <div class="font-medium">
              Grátis
            </div>
            <div class="font-serif text-[18px]">
              Premium
            </div>
          </div>

          <!-- Items -->
          <div class="relative flex flex-col mt-2">
            <div v-for="(item, index) in benefitsComparison" :key="item.title"
              class="relative z-10 py-4 flex justify-between items-center" :class="{
                'border-b border-[#B5B5B5]': index !== benefitsComparison.length - 1
              }">
              <div class="text-base text-[#252120]">
                {{ item.title }}
              </div>
              <div class="ml-auto min-w-[60px] flex justify-center">
                <Check v-if="item.free" class="size-6 text-green-500" />
                <X v-else class="size-6 text-red-500" />
              </div>
              <div class="ml-4 min-w-[60px] flex justify-center">
                <Check v-if="item.premium" class="size-6 text-green-500" />
                <X v-else class="size-6 text-red-500" />
              </div>
            </div>

            <!-- Decorator -->
            <div class=" absolute top-0 right-0 w-[60px] h-full bg-white rounded-2xl">

            </div>
          </div>
        </div>
      </div>

      <div class="bg-[#DAF0FF] pt-10 pb-20" :class="{
        '!pb-64': shouldShowAllPlans
      }" />

      <!-- Start trial box -->
      <div
        class="fixed bottom-0 min-h-[220px] z-50 bg-white w-screen rounded-3xl p-6 shadow-[0_-10px_15px_-3px_rgba(0,0,0,0.05),0_-4px_6px_-2px_rgba(0,0,0,0.05)] text-center transition-all duration-[0.4s]"
        :class="{
          'translate-y-0': scrollPercentage > 0.5 || !hasTrialEligibility,
          'translate-y-full': scrollPercentage <= 0.5 && hasTrialEligibility
        }">
        <div v-if="!isPurchasing">
          <div v-if="!shouldShowAllPlans">
            <h4>
              <b>Grátis por {{ bestTrialOffering?.product.introPrice?.periodNumberOfUnits }} dias</b>, depois <b>{{
                bestTrialOffering?.product.priceString }}</b> por ano. <span class="text-sm text-[#54514E]">Cancele
                quando
                quiser.</span>
            </h4>
            <Motion as="button" :press="{ scale: 0.96 }" @click="handlePurchaseTrial"
              class="select-none px-10 h-12 w-full rounded-full bg-primary border-accent border-2 text-white font-medium mx-auto mt-2">
              <div>
                <div class="font-semibold">Começar meu teste grátis</div>
              </div>
            </Motion>
            <button @click="showAllPlans = true" class="font-medium text-primary w-full py-4">
              Ver todos planos
            </button>
          </div>

          <!-- List plans -->
          <div v-if="shouldShowAllPlans" class="pb-4 grid gap-2">
            <h5 class="text-gray-600">
              Escolha seu plano
            </h5>
            <div v-for="(offering, i) in offerings"
              class="relative border-2 cursor-pointer rounded-[14px] p-2 px-4 flex items-center transition-all"
              @click="currentOfferingIndex = i" :class="{
                'border-primary text-primary bg-primary/5': currentOfferingIndex === i,
                'border-accent/60': currentOfferingIndex !== i,
              }">
              <div class="inline-flex flex-col items-start font-serif text-[20px]">
                {{ offering.product.planTitle }}
                <span v-if="offering.trialEligibility" class="font-sans text-sm text-gray-600">
                  Teste grátis por {{ offering.product.introPrice?.periodNumberOfUnits }} dias
                </span>
              </div>
              <div class="ml-auto text-right text-black">
                <div class="font-bold tracking-wide text-xl">{{ offering.product.priceString }}</div>
                <div class="text-sm text-accent whitespace-nowrap">
                  {{ offering.product.priceDescription }}
                </div>
              </div>
            </div>

            <UiButton :loading="isPurchasing" @click="handlePurchase" class="mt-2">
              <div class="flex flex-col">
                <span>{{ ctaLabel }}</span>
              </div>
            </UiButton>
            <div class="text-center text-sm text-gray-600 whitespace-pre-wrap">
              Cancele quando quiser.
            </div>
            <div class="flex gap-4 text-xs text-gray-600 text-center justify-center">
              <a :href="termsLink" class="underline" target="_blank">Termos de Uso</a>
              <a :href="privacyLink" class="underline" target="_blank">Política de Privacidade</a>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full justify-center items-center pt-10" v-else>
          <UiLoading :open="true" />
          <h4 class="font-serif mt-4 text-lg">Processando...</h4>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import { UiModal, UiButton, UiLoading } from '~/components/ui';
import { usePlans } from '~/domains/payment/composables/usePlans';
import { ChevronLeft, Lock, Bell, Star, Check, X } from 'lucide-vue-next';
import { useScroll } from '@vueuse/core'
import { useOfferings } from '~/domains/payment/composables/useOfferings';
import { dayjs } from '~/libs/dayjs';

const { modalOpened, benefitsComparison } = usePlans();
const { info, termsLink, privacyLink } = useDevice()
const { hasTrialEligibility, offerings, currentOfferingIndex, currentOffering, bestTrialOffering, handlePurchase, handlePurchaseTrial, isPurchasing } = useOfferings()

const showAllPlans = ref(false)

const ctaLabel = computed(() => {
  if (currentOffering.value?.trialEligibility) return 'Começar meu teste grátis'
  return 'Continuar'
})

const shouldShowAllPlans = computed(() => {
  return showAllPlans.value || !hasTrialEligibility.value
})

const trialEndsAt = computed(() => {
  return dayjs().add(3, 'day').format('D [de] MMMM, YYYY')
})

const paywallContainer = ref<HTMLElement>()
const { y } = useScroll(paywallContainer)

const containerHeight = computed(() => {
  return paywallContainer.value?.clientHeight ?? 2000
})

const scrollPercentage = computed(() => {
  return y.value / containerHeight.value
})

const shouldHaveExtraPadding = computed(() => {
  return info.value.isApp && info.value.isIphone && info.value.isPortrait
})

const scrollToBottom = () => {
  paywallContainer.value?.scrollTo({
    top: paywallContainer.value.scrollHeight,
    behavior: 'smooth'
  });
}
</script>