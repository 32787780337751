import { usePurchasePackage } from "../mutations/usePurchasePackage"
import { useListAppOfferings } from "../queries/useListAppOfferings"

const currentOfferingIndex = ref(0)
export const useOfferings = () => {
  const { mutate: purchasePackage, isPending: isPurchasing } = usePurchasePackage()
  const { data: offerings } = useListAppOfferings()

  const hasTrialEligibility = computed(() => {
    return !!offerings.value?.some((offering) => offering.trialEligibility)
  })

  const currentOffering = computed(() => {
    return offerings.value?.[currentOfferingIndex.value]
  })

  const bestTrialOffering = computed(() => {
    const trialOfferings = offerings.value?.filter((offering) => offering.trialEligibility)
    return trialOfferings?.sort((a, b) => b.product.price - a.product.price)[0]
  })

  const handlePurchase = () => {
    if (!currentOffering.value) return

    purchasePackage(toRaw(currentOffering.value))
  }

  const handlePurchaseTrial = () => {
    if (!bestTrialOffering.value) return

    purchasePackage(toRaw(bestTrialOffering.value))
  }

  return { offerings, hasTrialEligibility, bestTrialOffering, currentOfferingIndex, currentOffering, handlePurchase, handlePurchaseTrial, isPurchasing }
}
