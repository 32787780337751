<template>
  <div>
    <div class="w-screen relative " v-if="!loading">
      <Navbar />
      <div class="container text-accent py-10">
        <NuxtPage />
      </div>

      <Toaster />
      <PaymentModal v-if="!isApp" />
      <MobilePaymentModal v-if="isApp" />
    </div>
    <div class="flex justify-center items-center h-screen" v-else>
      <UiLoading />
    </div>
  </div>
</template>

<script type="ts" setup>
import PaymentModal from '~/components/payment/payment-modal.vue';
import MobilePaymentModal from '~/components/payment/mobile-payment-modal.vue';
import { Toaster } from '~/components/toast'
import { UiLoading } from "~/components/ui";
import Navbar from '~/domains/navbar/components/root.vue'

const isApp = useIsApp();
const { loading, checkAuth } = useAuthHandler()

onMounted(async () => {
  await checkAuth()
})

useHead({
  title: "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
});

</script>

<style lang="sass" scoped>
body
  scrollbar-gutter: stable both-edges
</style>
