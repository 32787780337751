<template>
  <div v-motion :initial="{
    opacity: 0,
    x: 4,
  }" :enter="{
    opacity: 1,
    x: 0,
  }" class="flex items-center" v-if="!isInHome">
    <div v-if="!hideBackIcon" draggable="false" @click.stop.prevent="goBack()">
      <img draggable="false" class="cursor-pointer fill-red-400" src="/images/icons/back-nav.svg" />
    </div>
  </div>
  <div v-else class="flex items-center">
    <img v-motion :enter="{
      opacity: 1,
      scale: 1,
    }" :initial="{
      opacity: 0,
      scale: 0.4,
    }" src="~/assets/images/logo.png" class="relative z-10 h-8 w-8" />
    <span v-motion :enter="{
      opacity: 1,
      x: 0,
    }" :initial="{
      opacity: 0,
      x: -10,
    }" class="relative z-0 font-serif ml-2 text-accent text-[22px] hidden md:block">respostaCerta</span>
  </div>
</template>

<script type="ts" setup>
import { useNavbar } from '../composables/useNavbar';

const { goBack, hideBackIcon, isInHome } = useNavbar()

</script>